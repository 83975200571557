<template>
  <a-spin :spinning="loading">
    <a-row :gutter="[8, 8]">
      <a-col :span="24">
        {{ $t('warehouse.belonging_to_warehouse') }}: 
        <span v-if="pageInfo.warehouse">{{ pageInfo.warehouse.warehouseNo }}</span>
        <span v-else>-</span>
      </a-col>
      <a-col :span="24">{{ $t('warehouse.label_task_name') }}: {{ pageInfo.taskName }}</a-col>
      <a-col :span="24">
        {{ $t('warehouse.tag_task_number') }}: {{ pageInfo.taskNo }}
        <a-button size="small" style="margin-left: 5px;" danger @click="handleCancel">{{ $t('common.cancel') }}</a-button>
      </a-col>
      <a-col :span="24">{{ $t('warehouse.shipment_name') }}: {{ pageInfo.shipment?.shipmentName || '-' }}</a-col>
      <a-col :span="24">{{ $t('warehouse.se_shipment_number') }}: {{ pageInfo.shipment?.seShipmentNo || '-' }}</a-col>
    </a-row>
    <a-table
      style="overflow-x: auto;"
      class="mt-3"
      size="small"
      :columns="columns"
      :data-source="boxList"
      :pagination="false"
      :row-key="(record) => record.id"
    >
      <template #serialNumber="{ index, record }">
        {{ index + 1 }}
      </template>
      <template #boxNo="{ record }">
        {{ record.whBox?.boxNo || '-' }}
      </template>
      <template #selfBoxNo="{ record }">
        {{ record.whBox?.selfBoxNo || '-' }}
      </template>
      <template #boxLabelUrl="{ record }">
        <a-row :gutter="[8, 8]" type="flex" align="middle">
          <a-col v-if="record.boxLabelUrl && record.boxLabelUrl.length > 0">
            <div><CFileDisplay :fileUrl="record.boxLabelUrl[0].url" /></div>
            <div v-if="record.boxLabelUrl && record.boxLabelUrl.length > 0" style="margin-top: 2px;text-align: center; cursor: pointer;">
              <a-tooltip>
                <template #title>{{ $t('common.delete') }}</template>
                <delete-outlined style="color: #aaa;" @click="handleClearBoxLabel(record)" />
              </a-tooltip>
            </div>
          </a-col>
          <a-col>
            <a-upload
              v-model:file-list="record.boxLabelUrl"
              accept=" .jpeg,.jpg,.png,.pdf"
              :showUploadList="false"
              :customRequest="e => handleUpload(e, record)"
            >
              <a-button type="primary" ghost>
                <upload-outlined></upload-outlined>
                {{ (record.boxLabelUrl && record.boxLabelUrl.length > 0) ? $t('warehouse.replacement_box_label') : $t('warehouse.upload_box_label') }}
              </a-button>
            </a-upload>
          </a-col>
        </a-row>
      </template>
      <template #labelCount="{ record }">
        <a-select v-model:value="record.labelCount" :disabled="record.boxLabelUrl.length === 0" style="width: 150px;">
          <a-select-option
            v-for="(item, index) in boxCountList"
            :key="index"
            :value="item"
          >{{ item }}</a-select-option>
        </a-select>
      </template>
      <template #operation="{ record }">
        <a-button type="primary" ghost @click="handleOpenModal(record)">{{ $t('warehouse.add_product_tags') }}</a-button>
      </template>
      <template #footer>
        <a-row :gutter="8" type="flex" align="middle">
          <a-col>
            <a-checkbox v-model:checked="needNewShipmentName">{{ $t('warehouse.modify_shipment_name') }}</a-checkbox>
          </a-col>
          <a-col v-if="needNewShipmentName">
            <a-input v-model:value="formState.newShipmentName" :placeholder="$t('warehouse.shipment_name')" style="width: 250px;" allow-clear />
          </a-col>
        </a-row>
      </template>
      <template #expandedRowRender="{ record }">
        <a-table
          :columns="innerColumns"
          :data-source="record.boxProducts"
          :pagination="false"
          :row-key="(record) => record.rowKey"
        >
          <template #serialNumber="{ index }">
            {{ index + 1 }}
          </template>
          <template #productIdentifyImgs="{ record }">
            <a-row :gutter="8" v-if="record.productIdentifyImgs.length">
              <a-col v-for="(item, index) in record.productIdentifyImgs" :key="index">
                <CFileDisplay :fileUrl="item.url" />
              </a-col>
            </a-row>
            <span v-else>-</span>
          </template>
          <template #labelUrl="{ record }">
            <CFileDisplay :fileUrl="record.labelUrl[0].url" />
          </template>
          <template #operation="{ record, index }">
            <a-row :gutter="16">
              <a-col>
                <a-button type="ghost" @click="handleEdit(record)">{{ $t('common.edit') }}</a-button>
              </a-col>
              <a-col>
                <a-button type="ghost" @click="handleDelete(record, index)">{{ $t('common.remove') }}</a-button>
              </a-col>
            </a-row>
          </template>
        </a-table>
      </template>
    </a-table>
    <a-row class="mt-5 mb-3" type="flex" justify="end">
      <a-col class="mr-3">
        <a-button type="primary" :loading="confirmLoading" @click="handleConfirm">{{ $t('warehouse.save_and_next') }}</a-button>
      </a-col>
    </a-row>
  </a-spin>
  <AddProductLabels ref="addProductLabelsRef" @confirm="handleSetTable" />
</template>

<script>
import { defineComponent, reactive, toRefs, ref, watch } from 'vue';
import { Button, Checkbox, Col, Input, InputNumber, Modal, Popover, Row, Select, Spin, Table, Tag, Tooltip, Upload, message } from 'ant-design-vue';
import CFileDisplay from '@/views/components/CFileDisplay.vue';
import AddProductLabels from './AddProductLabels';
import { useI18n } from "vue-i18n/index";
import { useRouter } from 'vue-router';
import { uploadImg } from "@/api/modules/common/index.js";
import {
  getTransitsLabelChangeTaskById,
  getTransitsLabelChangeTaskDetailList,
  setBoxProduct, 
  cancelTransitsLabelChangeTask,
} from '@/api/modules/transit/labelChange.js';
import { setFormStateValue } from '@/utils/general.js';
import { useTab } from "@/hooks/tabs/index";

export default defineComponent({
  name: 'stepTwo',
  components: {
    ASpin: Spin,
    ARow: Row,
    ACol: Col,
    AButton: Button,
    ATable: Table,
    AUpload: Upload,
    AInputNumber: InputNumber,
    ACheckbox: Checkbox,
    AInput: Input,
    APopover: Popover,
    ASelect: Select,
    ATag: Tag,
    ATooltip: Tooltip,
    ASelectOption: Select.Option,
    CFileDisplay,
    AddProductLabels,
  },
  props: {
    taskId: String,
  },
  setup (props) {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const { delVisitedRoute } = useTab();

    const addProductLabelsRef = ref(null);

    const state = reactive({
      loading: false,
      confirmLoading: false,
      needNewShipmentName: false,
      formState: {
        id: null,
        newShipmentName: null,
      },
      pageInfo: {
        warehouse: null,
        taskName: null,
        taskNo: null,
        shipment: null,
      },
      boxList: [],
      boxCountList: [1, 2, 4],
    })

    const columns = [
      {
        width: 80,
        title: () => vueI18n.t('warehouse.serial_number'),
        slots: {
          customRender: 'serialNumber',
        }
      },
      {
        width: 200,
        title: () => vueI18n.t('warehouse.box_id'),
        slots: {
          customRender: 'boxNo',
        }
      },
      {
        width: 200,
        title: () => vueI18n.t('common.user_defined_box_no'),
        slots: {
          customRender: 'selfBoxNo',
        }
      },
      {
        width: 300,
        title: () => vueI18n.t('warehouse.new_box_label'),
        slots: {
          customRender: 'boxLabelUrl',
        }
      },
      {
        width: 200,
        title: () => vueI18n.t('warehouse.box_label_quantity'),
        slots: {
          customRender: 'labelCount',
        }
      },
      {
        width: 120,
        slots: {
          customRender: 'operation',
        }
      },
    ];

    const innerColumns = [
      {
        width: 80,
        title: () => vueI18n.t('warehouse.serial_number'),
        slots: {
          customRender: 'serialNumber',
        }
      },
      {
        dataIndex: 'productIdentify',
        width: 120,
        title: () => vueI18n.t('warehouse.identifiable_product_information'),
      },
      {
        width: 150,
        title: () => vueI18n.t('warehouse.recognizable_product_images'),
        slots: {
          customRender: 'productIdentifyImgs',
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('warehouse.new_product_labeling_document'),
        slots: {
          customRender: 'labelUrl',
        }
      },
      {
        dataIndex: 'newProductIdentify',
        width: 120,
        title: () => vueI18n.t('warehouse.new_product_label'),
      },
      {
        dataIndex: 'productCount',
        width: 100,
        title: () => vueI18n.t('warehouse.product_quantity'),
      },
      {
        width: 150,
        slots: {
          customRender: 'operation',
        }
      },
    ];

    const handleClearBoxLabel = (record) => {
      record.boxLabelUrl = [];
      record.labelCount = 0;
    }

    const handleBack = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push('/transit/labelchange');
    };

    const handleCancel = () => {
      Modal.confirm({
        title: vueI18n.t("common.operation"),
        content: vueI18n.t("common.are_you_sure_cancel"),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          return cancelTransitsLabelChangeTask({ id: state.formState.id }).then(() => {
            message.success(vueI18n.t("common.successfully_canceled"));
            handleBack();
          });
        },
      });
    }

    const handleOpenModal = ({ id, whBox, boxProducts }, record = null) => {
      let data = { id, whBox, boxProducts }
      addProductLabelsRef.value.open(data, record);
    }

    const handleSetTable = (dataRow) => {
      let { id, rowKey } = dataRow;
      state.boxList.forEach(item => {
        if (item.id === id) {
          let product = item.boxProducts.find(item => item.rowKey === rowKey);
          if (product) {
            setFormStateValue(product, dataRow);
          } else {
            item.boxProducts.push(dataRow);
          }
        }
      });
    }

    const handleUpload = (file, record) => {
      let uploadData = new FormData();
      uploadData.append("file", file.file);
      uploadData.append("functionModule", 21);
      record.loading = true;
      uploadImg(uploadData)
        .then(({ result }) => {
          if (Array.isArray(result) && result.length > 0) {
            let file = record.boxLabelUrl[0];
            file.status = 'done';
            file.url = result[0].url;
            record.labelCount = state.boxCountList[0];
          }
        })
        .catch(() => {
          record.boxLabelUrl.pop();
        })
        .finally(() => {
          record.loading = false;
        });
    };

    const handleEdit = (record) => {
      let shipment = state.boxList.find(item => item.id === record.id);
      if (shipment) {
        handleOpenModal(shipment, record);
      }
    }

    const handleDelete = (record, index) => {
      let rowIndex = state.boxList.findIndex(item => item.id === record.id);
      if (rowIndex > -1) {
        state.boxList[rowIndex].boxProducts.splice(index, 1);
      }
    }

    // 获取提交数据
    const getBoxDetailData = () => {
      let taskDetails = state.boxList.map(item => {
        let boxProducts = item.boxProducts.map(item => ({
          productIdentify: item.productIdentify,
          productIdentifyImgs: item.productIdentifyImgs.map(item => item.url),
          labelUrl: item.labelUrl.map(item => item.url)[0],
          newProductIdentify: item.newProductIdentify,
          productCount: item.productCount,
        }));

        let boxLabelUrl = item.boxLabelUrl.length > 0 ? item.boxLabelUrl[0].url : null;
        let labelCount = boxLabelUrl ? item.labelCount : 0;

        return {
          id: item.id,
          boxLabelUrl,
          labelCount,
          boxProducts,
        }
      });

      return taskDetails;
    }

    // 验证
    const validateFormData  = (data) => {
      if (!data.id) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.label_task')]));
        return false;
      }

      if (!data.taskDetails) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.box_information')]));
        return false;
      }

      let result = true;
      let errorMsg = '';
      data.taskDetails.forEach(item => {
        if (item.boxLabelUrl) {
          if (item.labelCount === 0) {
            result = false;
            errorMsg = vueI18n.t('warehouse.the_box_label_needs_to_be_greater_than_p0', [0]);
          }
        } else {
          if (item.boxProducts.length === 0) {
            result = false;
            errorMsg = vueI18n.t('warehouse.box_label_or_product_information_not_empty');
          }
        }
      });

      if (!result) {
        message.error(errorMsg);
        return false;
      }

      if (state.needNewShipmentName && !data.newShipmentName) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.new_shipment_name')]));
        return false;
      }

      return result;
    }

    const handleConfirm = () => {
      let taskDetails = getBoxDetailData();
      const data = {
        id: state.formState.id,
        newShipmentName: state.formState.newShipmentName,
        taskDetails,
      }
      let validateRes = validateFormData(data);
      if (validateRes) {
        state.confirmLoading = true;
        setBoxProduct(data).then(() => {
          message.success(vueI18n.t('common.succeed'));
          handleNext();
        }).catch(() => {}).finally(() => {
          state.confirmLoading = false;
        });
      }
    }

    const handleNext = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push('/transit/labelchange/confirm/' + state.formState.id);
    }

    const getSecondStepDetail = async () => {
      try {
        state.loading = true;
        let { result: shipmentInfo } = await getTransitsLabelChangeTaskById({ id: state.formState.id });
        if (shipmentInfo) {
          setFormStateValue(state.pageInfo, shipmentInfo);
        }

        let { result: list } = await getTransitsLabelChangeTaskDetailList({ taskIds: [state.formState.id], isNeedBox: true })
        if (Array.isArray(list)) {
          state.boxList = list.map(item => {
            item.boxLabelUrl = [];
            item.boxProducts = [];
            item.loading = false;
            return item;
          });
        } else {
          state.boxList = [];
        }
      } catch (error) {
      } finally {
        state.loading = false;
      }
    }

    watch(() => props.taskId, (value) => {
      if (value) {
        state.formState.id = value;
        getSecondStepDetail();
      }
    }, { immediate: true });

    return {
      ...toRefs(state),
      addProductLabelsRef,
      columns,
      innerColumns,
      handleClearBoxLabel,
      handleCancel,
      handleOpenModal,
      handleSetTable,
      handleUpload,
      handleEdit,
      handleDelete,
      handleConfirm,
    }
  }
})
</script>

<style scoped>

</style>