<template>
  <Content>
    <template #contentTitle>{{ $t('menu.transit_label_change_create') }}</template>
    <template #contentBody>
      <StepsRow :current="pageStep" />
      <StepTwo :task-id="formState.taskId" />
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import Content from '@/views/components/Content.vue';
import StepsRow from './components/StepsRow';
import StepTwo from './components/StepTwo';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'transit_label_change_uploadtags',
  components: {
    Content,
    StepsRow,
    StepTwo,
  },
  setup () {
    const route = useRoute();

    const state = reactive({
      pageStep: 2,
      formState: {
        taskId: null,
      }
    });

    onMounted(() => {
      let params = route.params;
      if ('id' in params) {
        let taskId = params.id;
        state.formState.taskId = taskId;
      }
    });

    return {
      ...toRefs(state),
    }
  }
})
</script>

<style scoped></style>